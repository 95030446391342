import { Chat, Conversation } from "../types";
import axiosInstance from "./axiosInstance";
import { URLS } from "./constants";

export const startNewChat = async (conversationData: {
  focus_ids: string[];
  social_media_ids: string[];
}): Promise<Conversation> => {
  try {
    const response = await axiosInstance.post(
      "/chatbotv2/new_conversation",
      conversationData,
    );
    return response.data;
  } catch (error: any) {
    // Type assertion
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating chat");
    }
  }
};

export const getAllConversationsByUser = async (): Promise<Conversation[]> => {
  try {
    const response = await axiosInstance.get("/chatbotv2/conversations");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversations");
    }
  }
};

export const getConversationHistory = async (
  conversationId: string,
): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get(
      `/chatbotv2/history`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversation history");
    }
  }
};

export const postFirstMessage = async (
  conversation_id: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(
      "/chatbotv2/first_message",
      { conversation_id }, // Envoi d'un objet contenant conversation_id
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching first message");
    }
  }
};

export const askQuestionToMoodee = async (
  conversation_id: string,
  content: string,
  onChunkReceived: (
    chunk: string,
    metadata?: { user_id: string; moodee_id: string | null },
  ) => void,
): Promise<void> => {
  try {
    const response = await fetch(
      `${URLS.API.CURRENT}/chatbotv2/ask_moodee_v2`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ conversation_id, content }),
      },
    );

    if (!response.ok) {
      throw new Error("Erreur lors du fetch en streaming");
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirstChunk = true;

    if (reader) {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        if (isFirstChunk) {
          try {
            // On cherche le séparateur
            const [jsonPart, textPart] = chunk.split("\n---\n");
            const metadata = JSON.parse(jsonPart);
            if (metadata.type === "ids") {
              onChunkReceived("", metadata);
              isFirstChunk = false;
              // Si on a du texte après le JSON, on le traite
              if (textPart) {
                onChunkReceived(textPart);
              }
              continue;
            }
          } catch {
            // Si ce n'est pas du JSON, c'est un chunk normal
            onChunkReceived(chunk);
          }
        } else {
          onChunkReceived(chunk);
        }
      }
    }
  } catch (error: any) {
    throw new Error(error.message || "Erreur lors de la requête en streaming");
  }
};

export const deleteConversation = async (conversationId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_conversation`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting  history");
    }
  }
};

export const deleteChat = async (chatId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_chat`, // Ne pas mettre le chatId dans le chemin
      { params: { chat_id: chatId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting chat");
    }
  }
};

export const deleteChatsAfterDate = async (chatId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_chats_after_date`,
      {
        params: { chat_id: chatId },
      },
    ); // Send data in the body
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error modifying chat");
    }
  }
};

export const toggleChatFavorite = async (chatId: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.put(
      `/chatbotv2/favorite`,
      {},
      { params: { chat_id: chatId } },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error toggling chat favorite status");
    }
  }
};

export const getFavoritesPromptsByUser = async (): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get("/chatbotv2/favorites");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching favorite prompts");
    }
  }
};

export const toggleThumbs = async (
  chatId: string,
  thumbs: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.put(
      `/chatbotv2/thumbs`,
      {},
      { params: { chat_id: chatId, thumbs } },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error toggling chat thumbs status");
    }
  }
};
