import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { format, subMonths, subYears } from "date-fns";
import { CircleCheck, PlusIcon, XIcon } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { getFocusByID } from "../lib/focus";
import { createNewScraping } from "../lib/scraping";
import { cn } from "../lib/utils";
import Header from "./Header";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Input } from "./ui/input";
import { MultiSelect } from "./ui/multi-select";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { ScrapingData } from "../types";

type KeywordInput = {
  id: number;
  value: string;
};

const Configuration: React.FC = () => {
  const { user, socialMedias, focuses } = useUser();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage modal
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productOption, setProductOption] = useState<string>("");
  const [dateOption, setDateOption] = useState<string>("");
  const [newFocusName, setNewFocusName] = useState<string>("");
  const [newScrapingFocusName, setNewScrapingFocusName] = useState<string>("");
  const [existingScrapingFocusId, setExistingScrapingFocusId] =
    useState<string>(""); // State to store the selected focus ID
  const [scrapingStartDate, setScrapingStartDate] = useState<string>("");
  const [scrapingSocialMedias, setScrapingSocialMedias] = useState<string[]>(
    [],
  );
  const [keywordInputs, setKeywordInputs] = useState<KeywordInput[]>([
    { id: 1, value: "" },
  ]);

  // State for array of arrays of keywords
  const [scrapingKeywordsGroup, setScrapingKeywordsGroup] = useState<
    string[][]
  >([]);

  const handleDateOptionChange = (option: string) => {
    setDateOption(option); // Met à jour l'option sélectionnée

    const today = new Date();
    let calculatedDate: Date;

    switch (option) {
      case "1month":
        calculatedDate = subMonths(today, 1); // Date d'il y a 1 mois
        break;
      case "3months":
        calculatedDate = subMonths(today, 3); // Date d'il y a 3 mois
        break;
      case "6months":
        calculatedDate = subMonths(today, 6); // Date d'il y a 6 mois
        break;
      case "1year":
        calculatedDate = subYears(today, 1); // Date d'il y a 1 an
        break;
      case "custom":
        setScrapingStartDate(""); // Laisse le champ vide pour personnalisation
        return;
      default:
        return;
    }

    // Format la date au format `YYYY-MM-DD` pour le champ input de type "date"
    const formattedDate = format(calculatedDate, "yyyy-MM-dd");
    setScrapingStartDate(formattedDate);
  };

  const handleKeywordInputChange = (id: number, value: string): void => {
    setKeywordInputs((prev) =>
      prev.map((input) =>
        input.id === id ? { ...input, value: value } : input,
      ),
    );
  };

  // Add the keywords array to the main array of keywords
  const handleAddKeywordInput = () => {
    setKeywordInputs((prev) => [...prev, { id: prev.length + 1, value: "" }]);
  };

  const handleAddKeywordGroup = () => {
    setScrapingKeywordsGroup((prev) => [
      ...prev,
      keywordInputs.map((input) => input.value),
    ]);
    setKeywordInputs([{ id: 1, value: "" }]);
  };

  const handleRemoveKeywords = (index: number) => {
    setScrapingKeywordsGroup((prev) => prev.filter((_, i) => i !== index));
  };

  // Function to validate form fields
  const isFormValid = () => {
    return (
      (newScrapingFocusName.trim() !== "" ||
        existingScrapingFocusId.trim() !== "") &&
      scrapingSocialMedias.length > 0 &&
      scrapingStartDate.trim() !== "" &&
      scrapingKeywordsGroup.length > 0
    );
  };

  const handleStartScraping = async () => {
    if (!isFormValid()) return;

    setIsLoading(true);

    const scrapingDateFormatted = new Date(scrapingStartDate).toISOString();

    try {
      const scrapingData: ScrapingData = {
        keyword_logic: scrapingKeywordsGroup,
        social_media_ids: scrapingSocialMedias,
        start_date: scrapingDateFormatted,
        focus_name:
          productOption === "newProduct" ? newScrapingFocusName : undefined,
        focus_id:
          productOption === "newProduct" ? undefined : existingScrapingFocusId,
      };

      const newScraping = await createNewScraping(scrapingData);

      await getFocusByID(newScraping.focus_id);
      navigate("/focus");
    } catch (err: any) {
      console.error(err.message || "Failed to start scraping.");
    } finally {
      setIsLoading(false);
    }
  };

  const isFocusLimitReached = focuses.length >= (user?.max_focuses ?? 0);

  return (
    <>
      <Header title="Paramétrage" />
      <div className="px-4 pb-10 sm:px-8 lg:px-12">
        <h2 className="text-xl font-bold">
          Paramétrez une nouvelle récupération de données
        </h2>
        <p className="text-gray-500">
          Suivez les étapes ci-dessous afin de configurer votre analyse
          rapidement et efficacement
        </p>

        {/* Etape 1 */}
        <div className="mt-8 grid gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-5 2xl:grid-cols-6">
          <div className="max-w-56">
            <h3 className="font-bold">Etape 1 :</h3>
            <p className="text-sm text-gray-500">
              Choisissez un sujet à analyser
            </p>
          </div>
          <div className="flex max-w-2xl flex-col gap-5 rounded-3xl border border-blue p-5 md:col-span-3">
            <RadioGroup
              value={productOption}
              onValueChange={(value) => {
                setProductOption(value);
                setNewScrapingFocusName("");
              }}
              className="gap-8"
            >
              <div className="space-y-4">
                {isFocusLimitReached && (
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-orange">
                      Vous avez atteint la limite de votre abonnement actuel de{" "}
                      {user?.max_focuses} focus.
                    </p>
                    <Button>Passez au plan supérieur</Button>
                  </div>
                )}
                {/* <div
                      className="relative h-14 w-14 rounded-[50%]"
                      style={{
                        background: `
                          radial-gradient(closest-side, white 79%, transparent 80% 100%),
                          conic-gradient(#409CFF ${percentageFocus}%, #ECF6FF ${percentageFocus}% 100%)
                        `,
                      }}
                    >
                      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center text-sm font-bold text-blue">
                        {focuses.length}/{user?.max_focuses}
                      </div>
                    </div> */}

                <div className="flex gap-2">
                  <RadioGroupItem
                    value="newProduct"
                    id="newProduct"
                    className="mt-1 disabled:cursor-not-allowed"
                    disabled={isFocusLimitReached}
                  />
                  <div className={cn(isFocusLimitReached && "opacity-40")}>
                    <label
                      htmlFor="newProduct"
                      className="text-base font-semibold leading-4"
                    >
                      Nouveau focus
                    </label>
                    <p className="text-sm text-gray-500">
                      Créez un nouveau focus
                    </p>
                  </div>
                </div>
                <div
                  className={cn(
                    productOption !== "newProduct" &&
                      "cursor-not-allowed opacity-40",
                    "w-full pl-8",
                  )}
                >
                  <label htmlFor="focusName" className="text-sm font-bold">
                    Choisir un nouveau produit
                  </label>
                  <Input
                    type="text"
                    id="focusName"
                    value={newFocusName}
                    placeholder="Nom du produit"
                    onChange={(e) => {
                      setNewFocusName(e.target.value);
                      setNewScrapingFocusName(e.target.value);
                    }}
                    required
                    className="h-10 w-full"
                    disabled={productOption !== "newProduct"}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex gap-2">
                  <RadioGroupItem
                    value="existingProduct"
                    id="existingProduct"
                    className="mt-1 disabled:cursor-not-allowed"
                  />
                  <div>
                    <label
                      htmlFor="newProduct"
                      className="text-base font-semibold leading-5"
                    >
                      Focus existant
                    </label>
                    <p className="text-sm text-gray-500">
                      Utilisez un sujet préalablement configuré
                    </p>
                  </div>
                </div>
                <div
                  className={cn(
                    productOption !== "existingProduct" &&
                      "cursor-not-allowed opacity-40",
                    "w-full pl-8",
                  )}
                >
                  <label htmlFor="focusName" className="text-sm font-bold">
                    Choisir un sujet existant
                  </label>
                  <Select
                    onValueChange={(value) => setExistingScrapingFocusId(value)}
                    disabled={productOption !== "existingProduct"}
                  >
                    <SelectTrigger className="flex h-10 items-center justify-between rounded-full border border-blue bg-inherit text-sm capitalize text-dark hover:bg-inherit">
                      <SelectValue placeholder="Choisir un focus existant" />
                    </SelectTrigger>
                    <SelectContent>
                      {focuses
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((focus) => (
                          <SelectItem
                            key={focus.focus_id}
                            value={focus.focus_id}
                            className="focus:bg-orange-light"
                          >
                            {focus.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </RadioGroup>
          </div>
        </div>

        {/* Etape 2 */}
        <div className="mt-8 grid gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-5 2xl:grid-cols-6">
          <div className="max-w-56">
            <h3 className="font-bold">Etape 2 :</h3>
            <p className="text-sm text-gray-500">
              Définissez la période d'analyse
            </p>
          </div>
          <div className="flex max-w-2xl flex-col gap-5 rounded-3xl border border-blue p-5 md:col-span-3">
            <div>
              <p className="text-base font-semibold leading-4">
                Date de départ
              </p>
              <p className="text-sm text-gray-500">
                Choisissez la date de début pour la récupération des données
              </p>
            </div>

            <RadioGroup
              value={dateOption}
              onValueChange={handleDateOptionChange}
              className="grid grid-cols-2 gap-6 lg:grid-cols-4"
            >
              <div className="flex items-center gap-2">
                <RadioGroupItem
                  value="1month"
                  id="1month"
                  className="disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="1month"
                  className="text-base font-semibold leading-4"
                >
                  1 mois
                </label>
              </div>

              <div className="flex items-center gap-2">
                <RadioGroupItem
                  value="3months"
                  id="3months"
                  className="disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="3months"
                  className="text-base font-semibold leading-4"
                >
                  3 mois
                </label>
              </div>

              <div className="flex items-center gap-2">
                <RadioGroupItem
                  value="6months"
                  id="6months"
                  className="disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="6months"
                  className="text-base font-semibold leading-4"
                >
                  6 mois
                </label>
              </div>

              <div className="flex items-center gap-2">
                <RadioGroupItem
                  value="1year"
                  id="1year"
                  className="disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="1year"
                  className="text-base font-semibold leading-4"
                >
                  1 an
                </label>
              </div>

              <div className="flex items-center gap-2">
                <RadioGroupItem
                  value="custom"
                  id="custom"
                  className="disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="custom"
                  className="text-base font-semibold leading-4"
                >
                  Personnaliser
                </label>
              </div>
            </RadioGroup>

            <div
              className={cn(
                dateOption !== "custom" && "cursor-not-allowed opacity-40",
                "w-full pl-8",
              )}
            >
              <label htmlFor="startDate" className="text-sm font-bold">
                Choisir la date
              </label>
              <Input
                type="date"
                id="startDate"
                value={scrapingStartDate}
                min={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                    .toISOString()
                    .split("T")[0]
                }
                onChange={(e) => setScrapingStartDate(e.target.value)}
                required
                className="h-10 w-full"
                disabled={dateOption !== "custom"}
              />
            </div>
          </div>
        </div>

        {/* Etape 3 */}
        <div className="mt-8 grid gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-5 2xl:grid-cols-6">
          <div className="max-w-56">
            <h3 className="font-bold">Etape 3 :</h3>
            <p className="text-sm text-gray-500">Sélectionnez les réseaux</p>
          </div>
          <div className="flex max-w-2xl flex-col gap-5 rounded-3xl border border-blue p-5 md:col-span-3">
            <div>
              <p className="text-base font-semibold leading-4">
                Choisissez la ou les plateformes
              </p>
              <p className="text-sm text-gray-500">
                Choisissez un ou plusieurs réseaux sociaux que vous souhaitez
                inclure pour l'analyse
              </p>
            </div>

            <MultiSelect
              options={socialMedias.map((social) => ({
                label: social.name,
                value: social.social_media_id,
                icon: (() => {
                  if (social.formatted_name === "tiktok")
                    return IconBrandTiktok;
                  if (social.formatted_name === "google_news")
                    return IconBrandGoogle;
                  if (social.formatted_name === "instagram")
                    return IconBrandInstagram;
                  if (social.formatted_name === "amazon_reviews")
                    return IconBrandAmazon;
                  if (social.formatted_name === "linkedin")
                    return IconBrandLinkedin;
                  if (social.formatted_name === "x") return IconBrandX;
                  if (social.formatted_name === "facebook")
                    return IconBrandFacebook;
                  if (social.formatted_name === "google_maps_reviews")
                    return IconBrandGoogleMaps;
                  return IconTool;
                })(),
              }))}
              onValueChange={setScrapingSocialMedias}
              defaultValue={scrapingSocialMedias}
              placeholder="Media"
              variant="orange"
              animation={2}
              maxCount={3}
              className="h-12 p-0 px-6"
            />
          </div>
        </div>

        {/* Etape 4 */}
        <div className="mt-8 grid gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-5 2xl:grid-cols-6">
          <div className="max-w-56">
            <h3 className="font-bold">Etape 4 :</h3>
            <p className="text-sm text-gray-500">
              Configurez vos termes de recherche
            </p>
          </div>
          <div className="flex max-w-2xl flex-col gap-5 rounded-3xl border border-blue p-5 md:col-span-3">
            <div>
              <p className="text-base font-semibold leading-4">
                Définissez le ou les mots clés de votre recherche
              </p>
              <p className="text-sm text-gray-500">
                Organisez le ou les mots clés que vous souhaitez analyser en
                groupes distincs. Chaque groupe de mots clés sera étudié
                séparément.
              </p>
            </div>

            <div className="space-y-3">
              {keywordInputs.map((input, index) => (
                <div
                  key={input.id}
                  className={`flex items-center gap-3 ${
                    index === keywordInputs.length - 1 ? "flex-row" : ""
                  }`}
                >
                  <Input
                    type="text"
                    id={`keyword${input.id}`}
                    value={input.value}
                    placeholder={`Mot clé ${input.id}`}
                    onChange={(e) =>
                      handleKeywordInputChange(input.id, e.target.value)
                    }
                    required
                    className={`h-10 w-full ${
                      index === keywordInputs.length - 1 ? "flex-grow" : ""
                    }`}
                  />
                  {index === keywordInputs.length - 1 && (
                    <Button
                      onClick={handleAddKeywordInput}
                      className="w-fit gap-3 text-xs"
                      size="lg"
                    >
                      <span>Ajouter un mot clé au groupe</span>
                      <PlusIcon className="size-5 rounded-md bg-white p-0.5 text-blue" />
                    </Button>
                  )}
                </div>
              ))}
            </div>

            <Button
              onClick={handleAddKeywordGroup}
              className="mx-auto w-fit py-3"
              variant="outline"
              hover="jumping"
              disabled={keywordInputs.every((input) => !input.value)}
            >
              Valider ce/ces mot(s)-clé(s)
            </Button>

            {/* Displaying the added keywords arrays */}
            <div className="col-span-full -mt-2">
              <ul className="flex items-center gap-2">
                {scrapingKeywordsGroup.map((keywordsArray, index) => (
                  <li
                    className="flex w-fit items-center gap-3 rounded-full bg-blue-light py-0.5 pl-4 pr-2 text-sm"
                    key={index}
                  >
                    {keywordsArray.join(" / ")}
                    <button
                      onClick={() => handleRemoveKeywords(index)}
                      className="text-white"
                    >
                      <XIcon className="size-4 rounded-full bg-orange p-0.5 text-dark" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-8 grid gap-4 md:grid-cols-4 md:gap-6 xl:grid-cols-5 2xl:grid-cols-6">
          <div className="flex max-w-2xl items-center md:col-span-3 md:col-start-2">
            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
              <DialogTrigger asChild>
                <Button
                  className="mx-auto rounded-xl bg-green px-24 text-center text-white hover:bg-green/90"
                  hover="jumping"
                  disabled={!isFormValid() || isLoading} // Disable button if form is invalid or during loading
                >
                  {isLoading ? "Lancement..." : "Lancer le scraping"}
                </Button>
              </DialogTrigger>
              <DialogContent className="max-h-[calc(100vh-50px)] overflow-y-scroll">
                <DialogTitle className="mb-2 flex items-center gap-2">
                  <div className="w-fit rounded-full bg-green-light p-1.5">
                    <CircleCheck className="size-10 rounded-full bg-green p-1 text-white" />
                  </div>
                  Confirmation de la récupération de données
                </DialogTitle>
                <div className="space-y-4">
                  <p className="-mt-4 text-sm text-gray-400">
                    Vous êtes sur le point de lancer une nouvelle récupération
                    de données. Veuillez vérifier les informations suivantes
                    avant de continuer.
                  </p>
                  <div>
                    <p className="font-semibold">Produit :</p>
                    <p>
                      {productOption === "newProduct"
                        ? newScrapingFocusName
                        : focuses.find(
                            (focus) =>
                              focus.focus_id === existingScrapingFocusId,
                          )?.name}
                    </p>
                  </div>

                  <div>
                    <p className="font-semibold">Date de départ :</p>
                    <p>{scrapingStartDate}</p>
                  </div>

                  <div>
                    <p className="font-semibold">Réseaux sociaux :</p>
                    <ul>
                      {scrapingSocialMedias.map((socialMedia) => (
                        <li key={socialMedia}>
                          {
                            socialMedias.find(
                              (social) =>
                                social.social_media_id === socialMedia,
                            )?.name
                          }
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <p className="font-semibold">Mots clés :</p>
                    <ul>
                      {scrapingKeywordsGroup.map((keywordsArray, index) => (
                        <li key={index}>{keywordsArray.join(" / ")}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <Button
                    className="w-full rounded-lg"
                    variant="outline"
                    hover="jumping"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    className="w-full rounded-lg"
                    hover="jumping"
                    onClick={handleStartScraping} // Disable button if form is invalid or during loading
                  >
                    {isLoading ? "Lancement..." : "Lancer le scraping"}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default Configuration;
