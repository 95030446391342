import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import React from "react";
import { Focus, SocialMedia } from "../../types";
import { Button } from "../ui/button";
import { MultiSelect } from "../ui/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface NewChatFormProps {
  focuses: Focus[];
  selectedFocus: string | undefined;
  setSelectedFocus: (value: string) => void;
  selectedSocialMedia: string[];
  setSelectedSocialMedia: (value: string[]) => void;
  socialMedias: SocialMedia[];
  handleStartChat: (e: React.FormEvent) => void;
}

const NewChatForm = ({
  focuses,
  selectedFocus,
  setSelectedFocus,
  selectedSocialMedia,
  setSelectedSocialMedia,
  socialMedias,
  handleStartChat,
}: NewChatFormProps) => {
  return (
    <form
      onSubmit={handleStartChat}
      className="mx-auto mt-36 flex w-11/12 max-w-[750px] flex-col gap-5 rounded-3xl border border-gray-200 bg-white p-16 shadow-md"
    >
      <h3 className="mx-auto text-2xl font-semibold">
        Choisis ton produit et tes media
      </h3>

      <Select onValueChange={setSelectedFocus} value={selectedFocus}>
        <SelectTrigger className="flex h-12 w-full items-center justify-between rounded-full border border-gray-200 bg-inherit px-6 text-sm capitalize text-gray-500 shadow-sm hover:bg-inherit">
          <SelectValue placeholder="Produit" />
        </SelectTrigger>
        <SelectContent>
          {focuses
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((focus) => (
              <SelectItem
                key={focus.focus_id}
                value={focus.focus_id}
                className="focus:bg-green-light"
              >
                {focus.name}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>

      <MultiSelect
        options={socialMedias
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((social) => ({
            label: social.name,
            value: social.social_media_id,
            icon: (() => {
              if (social.formatted_name === "tiktok") return IconBrandTiktok;
              if (social.formatted_name === "google_news")
                return IconBrandGoogle;
              if (social.formatted_name === "instagram")
                return IconBrandInstagram;
              if (social.formatted_name === "amazon_reviews")
                return IconBrandAmazon;
              if (social.formatted_name === "linkedin")
                return IconBrandLinkedin;
              if (social.formatted_name === "x") return IconBrandX;
              if (social.formatted_name === "facebook")
                return IconBrandFacebook;
              if (social.formatted_name === "google_maps_reviews")
                return IconBrandGoogleMaps;
              return IconTool;
            })(),
          }))}
        onValueChange={setSelectedSocialMedia}
        defaultValue={selectedSocialMedia}
        placeholder="Media"
        variant="orange"
        animation={2}
        maxCount={3}
        className="h-12 p-0 px-6"
      />

      <Button
        type="submit"
        className="mx-auto w-fit rounded-lg px-5"
        hover="jumping"
      >
        Démarrer la conversation
      </Button>
    </form>
  );
};

export default NewChatForm;
