import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import {
  useChatState,
  useConversationSearch,
  useInitialData,
  useUrlParams,
} from "../hooks/chat-hooks";
import {
  askQuestionToMoodee,
  deleteChat,
  deleteChatsAfterDate,
  deleteConversation,
  getConversationHistory,
  getFavoritesPromptsByUser,
  postFirstMessage,
  startNewChat,
  toggleChatFavorite,
  toggleThumbs,
} from "../lib/askMoodee";
import { Chat } from "../types";
import ChatHeader from "./chat/ChatHeader";
import ChatInput from "./chat/ChatInput";
import ChatMessages from "./chat/ChatMessages";
import ChatMessagesLoader from "./chat/ChatMessagesLoader";
import ChatSidebar from "./chat/ChatSidebar";
import NewChatForm from "./chat/NewChatForm";

const ChatPage: React.FC = () => {
  const { user, socialMedias, focuses } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isInitialDataLoaded,
    conversations,
    favoritePrompts,
    setFavoritePrompts,
    conversationsLoading,
    setConversations,
  } = useInitialData();

  const {
    selectedFocus,
    setSelectedFocus,
    selectedSocialMedia,
    setSelectedSocialMedia,
  } = useUrlParams(socialMedias);

  const { searchConversation, setSearchConversation, filteredConversations } =
    useConversationSearch(conversations);

  const {
    currentConversationChats,
    setCurrentConversationChats,
    answerLoading,
    setAnswerLoading,
    answerFinished,
    setAnswerFinished,
    chatContent,
    setChatContent,
    moodeeWaitMessage,
    modifiedChatId,
    setModifiedChatId,
  } = useChatState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowChat, setShouldShowChat] = useState(false);
  const [error, setError] = useState("");
  const [searchChat, setSearchChat] = useState("");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleStartChat = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedFocus || selectedSocialMedia.length === 0) {
      setError("Please select a focus and a social media platform.");
      return;
    }

    try {
      const conversationData = {
        focus_ids: [selectedFocus],
        social_media_ids: selectedSocialMedia,
      };

      const newConversation = await startNewChat(conversationData);

      // Construire un objet conversation complet
      const completeConversation = {
        ...newConversation,
        focus_names: focuses
          .filter((f) => selectedFocus === f.focus_id)
          .map((f) => f.name),
        social_media_formatted_names: socialMedias
          .filter((sm) => selectedSocialMedia.includes(sm.social_media_id))
          .map((sm) => sm.formatted_name),
      };

      setConversations((prevConversations) => [
        ...prevConversations,
        completeConversation,
      ]);

      const firstMessage = await postFirstMessage(
        newConversation.conversation_id,
      );
      setCurrentConversationChats([firstMessage]);

      navigate(`?conversation_id=${newConversation.conversation_id}`);
      setShouldShowChat(true);

      setSelectedFocus("");
      setSelectedSocialMedia([]);
    } catch (error) {
      setShouldShowChat(false);
      setError("Failed to start a new conversation.");
      setAnswerLoading(false);
    }
  };

  const handleConversationSelect = useCallback(
    async (conversationId: string) => {
      try {
        navigate(`?conversation_id=${conversationId}`);
        setShouldShowChat(true); // Ajout de la gestion de l'affichage
        const data = await getConversationHistory(conversationId);
        setCurrentConversationChats(data);
      } catch (err: any) {
        setShouldShowChat(false); // Désactivation en cas d'erreur
        setError(err.message || "Failed to fetch conversation.");
      }
    },
    [setCurrentConversationChats, navigate, setShouldShowChat],
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentConversationChats || !chatContent) return;

    if (modifiedChatId) {
      handleDeleteChatsAfter(modifiedChatId);
    }

    const userTempId: string = crypto.randomUUID();
    const moodeeTempId: string = crypto.randomUUID();
    let realMoodeeId = moodeeTempId; // Pour garder une référence à l'ID mis à jour

    setAnswerFinished(false);

    setCurrentConversationChats((prev) =>
      prev
        ? [
            ...prev,
            {
              chat_id: userTempId,
              conversation_id: currentConversationChats[0].conversation_id,
              content: chatContent,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              favorite: false,
              author: "User",
              thumbs: null,
              favorite_summary: null,
            },
            {
              chat_id: moodeeTempId,
              conversation_id: currentConversationChats[0].conversation_id,
              content: "",
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              author: "Moodee",
              favorite: false,
              thumbs: null,
              favorite_summary: null,
            },
          ]
        : null,
    );

    setChatContent("");
    setAnswerLoading(true);
    setModifiedChatId(null);

    try {
      await askQuestionToMoodee(
        currentConversationChats[0].conversation_id,
        chatContent,
        (chunk, metadata) => {
          if (metadata) {
            if (metadata.moodee_id) {
              realMoodeeId = metadata.moodee_id || moodeeTempId;
            }
            setCurrentConversationChats(
              (prev) =>
                prev?.map((chat) => {
                  if (chat.chat_id === userTempId) {
                    return { ...chat, chat_id: metadata.user_id };
                  }
                  if (chat.chat_id === moodeeTempId && metadata.moodee_id) {
                    return { ...chat, chat_id: metadata.moodee_id };
                  }
                  return chat;
                }) ?? null,
            );
          } else {
            setAnswerLoading(false);
            setCurrentConversationChats(
              (prev) =>
                prev?.map((chat) =>
                  chat.chat_id === realMoodeeId
                    ? { ...chat, content: chat.content + chunk }
                    : chat,
                ) ?? null,
            );
          }
        },
      );

      setAnswerFinished(true);
    } catch (err) {
      setError("Failed to fetch response");
      setAnswerLoading(false);
    }
  };

  const handleDeleteConversation = async () => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    try {
      await deleteConversation(currentConversationChats[0].conversation_id);
      setConversations((prev) =>
        prev.filter(
          (conv) =>
            conv.conversation_id !==
            currentConversationChats[0].conversation_id,
        ),
      );
      setCurrentConversationChats(null);
      setIsModalOpen(false);
      setShouldShowChat(false);
      navigate("/chat", { replace: true }); // au lieu de window.history.replaceState
    } catch (err: any) {
      setError("Failed to delete conversation.");
    }
  };

  const handleDeleteChat = async (chatId: string) => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    try {
      await deleteChat(chatId);
      setCurrentConversationChats((prev) =>
        prev ? prev.filter((chat) => chat.chat_id !== chatId) : null,
      );
    } catch (err: any) {
      setError("Failed to delete chat.");
    }
  };

  const handleDeleteChatsAfter = async (chatId: string) => {
    if (!currentConversationChats) {
      setError("No conversation selected.");
      return;
    }

    setCurrentConversationChats((prev) => {
      if (!prev) return null;

      const modifiedChat = prev.find((chat) => chat.chat_id === chatId);
      if (!modifiedChat) {
        setError("Chat not found in the current conversation.");
        return prev;
      }

      const modifiedDate = new Date(modifiedChat.created_at);
      return prev
        .filter((chat) => {
          const chatDate = new Date(chat.created_at);
          return chatDate <= modifiedDate;
        })
        .filter((chat) => chat.chat_id !== chatId);
    });

    await deleteChatsAfterDate(chatId);
  };

  const handleFavoriteChat = async (chatId: string) => {
    const currentChat = favoritePrompts?.find(
      (chat) => chat.chat_id === chatId,
    );

    const isRemoving = currentChat?.favorite;

    // Mettre à jour l'UI immédiatement
    setCurrentConversationChats((prev) =>
      prev
        ? prev.map((chat) =>
            chat.chat_id === chatId
              ? { ...chat, favorite: !chat.favorite }
              : chat,
          )
        : null,
    );

    if (isRemoving) {
      // Si on supprime, on retire d'abord des favoris
      setFavoritePrompts((prev) => prev.filter((p) => p.chat_id !== chatId));
    } else {
      // Si on ajoute, on met le message temporaire
      const currentChat = currentConversationChats?.find(
        (chat) => chat.chat_id === chatId,
      );
      if (!currentChat) return;

      const tempPrompt: Chat = {
        chat_id: chatId,
        conversation_id: currentChat.conversation_id,
        content: "Enregistrement du prompt...",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        author: currentChat.author,
        favorite: true,
        thumbs: null,
        favorite_summary: "Enregistrement en cours...",
      };

      setFavoritePrompts((prev) => [...prev, tempPrompt]);
    }

    try {
      await toggleChatFavorite(chatId);
      const favorites = await getFavoritesPromptsByUser();
      setFavoritePrompts(favorites);
    } catch (error) {
      // Retour à l'état initial en cas d'erreur
      if (isRemoving) {
        const favorites = await getFavoritesPromptsByUser();
        setFavoritePrompts(favorites);
      } else {
        setFavoritePrompts((prev) => prev.filter((p) => p.chat_id !== chatId));
      }
      setCurrentConversationChats((prev) =>
        prev
          ? prev.map((chat) =>
              chat.chat_id === chatId
                ? { ...chat, favorite: !chat.favorite }
                : chat,
            )
          : null,
      );
    }
  };

  const handleThumbs = async (chatId: string, newThumb: string) => {
    await toggleThumbs(chatId, newThumb);
    setCurrentConversationChats(
      (prev) =>
        prev?.map((chat) =>
          chat.chat_id === chatId
            ? { ...chat, thumbs: chat.thumbs === newThumb ? null : newThumb }
            : chat,
        ) ?? null,
    );
  };

  useEffect(() => {
    if (currentConversationChats && answerFinished === false) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [currentConversationChats, answerFinished]);

  useEffect(() => {
    if (!isInitialDataLoaded) return;

    const urlParams = new URLSearchParams(location.search);
    const convId = urlParams.get("conversation_id");

    if (convId && !currentConversationChats) {
      handleConversationSelect(convId);
    }
    setShouldShowChat(!!convId);
  }, [
    isInitialDataLoaded,
    location.search,
    currentConversationChats,
    handleConversationSelect,
  ]);

  const selectedConversation = conversations.find(
    (conv) =>
      conv.conversation_id === currentConversationChats?.[0]?.conversation_id,
  );

  return (
    <div className="relative h-full p-4">
      <div className="relative z-50 flex h-full">
        <ChatSidebar
          searchConversation={searchConversation}
          setSearchConversation={setSearchConversation}
          conversationsLoading={conversationsLoading}
          filteredConversations={filteredConversations}
          handleConversationSelect={handleConversationSelect}
          selectedConversation={selectedConversation}
          setCurrentConversationChats={setCurrentConversationChats}
        />

        <div className="mx-auto flex size-full flex-col justify-between">
          {shouldShowChat ? (
            !selectedConversation ? (
              <ChatMessagesLoader />
            ) : (
              <>
                <ChatHeader
                  selectedConversation={selectedConversation}
                  searchChat={searchChat}
                  setSearchChat={setSearchChat}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleDeleteConversation={handleDeleteConversation}
                />

                {currentConversationChats && (
                  <ChatMessages
                    currentConversationChats={currentConversationChats}
                    searchTerm={searchChat}
                    user={user}
                    answerLoading={answerLoading}
                    moodeeWaitMessage={moodeeWaitMessage}
                    answerFinished={answerFinished}
                    setChatContent={setChatContent}
                    setModifiedChatId={setModifiedChatId}
                    handleDeleteChat={handleDeleteChat}
                    handleFavoriteChat={handleFavoriteChat}
                    handleThumbs={handleThumbs}
                    messagesEndRef={messagesEndRef}
                  />
                )}
                <ChatInput
                  selectedConversation={selectedConversation}
                  answerLoading={answerLoading}
                  chatContent={chatContent}
                  setChatContent={setChatContent}
                  modifiedChatId={modifiedChatId}
                  setModifiedChatId={setModifiedChatId}
                  handleSubmit={handleSubmit}
                  handleFavoriteChat={handleFavoriteChat}
                  favoritePrompts={favoritePrompts}
                />
              </>
            )
          ) : (
            <NewChatForm
              focuses={focuses}
              selectedFocus={selectedFocus || ""}
              setSelectedFocus={setSelectedFocus}
              selectedSocialMedia={selectedSocialMedia}
              setSelectedSocialMedia={setSelectedSocialMedia}
              socialMedias={socialMedias}
              handleStartChat={handleStartChat}
            />
          )}
        </div>
      </div>

      <img
        alt="shadow"
        src="chat-shadow.png"
        className="absolute bottom-0 right-0 z-0 hidden blur-[80px]"
      />
    </div>
  );
};

export default ChatPage;
