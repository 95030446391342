const ChatSidebarLoader = () => (
  <div className="flex flex-col gap-2">
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
    <div className="flex h-8 w-full animate-pulse rounded-full bg-gray-200 px-2 py-1"></div>
  </div>
);

export default ChatSidebarLoader;
