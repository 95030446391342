import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserInfo, refreshToken } from "../lib/auth";
import { getAllFocusesByUser } from "../lib/focus";
import { getAllLanguages } from "../lib/languages";
import { getAllSocialMedias } from "../lib/socialMedias";
import { Focus, Language, SocialMedia, User } from "../types";

interface UserContextType {
  user: User | undefined;
  languages: Language[];
  socialMedias: SocialMedia[];
  focuses: Focus[];
  loading: boolean;
  error: string | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);
  const [focuses, setFocuses] = useState<Focus[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    // Skip fetching user info on /login and /signup pages
    if (
      location.pathname === "/auth/login" ||
      location.pathname === "/auth/signup" ||
      location.pathname === "/auth/reset-password-request" ||
      location.pathname === "/auth/reset-password"
    ) {
      setLoading(false);
      return;
    }

    const fetchUserInfo = async () => {
      try {
        // Attempt to get the current user's info
        const userData = await getUserInfo();
        setUser(userData);
        setError(null);
      } catch (error: any) {
        if (error.response?.status === 401) {
          try {
            // If we get a 401 error, attempt to refresh the token
            await refreshToken();

            // After refreshing, try to fetch the user info again
            const userData = await getUserInfo();
            setUser(userData);
            setError(null);
          } catch (refreshError) {
            console.error("Failed to refresh token", refreshError);
            setUser(undefined);
            setError("Failed to refresh token. Please log in again.");
          }
        } else {
          console.error("Failed to fetch user info", error);
          setUser(undefined);
          setError("Failed to fetch user info");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [location.pathname]); // Re-run the effect if the location changes

  useEffect(() => {
    const loadLanguagesAndSocialMedias = async () => {
      try {
        const languagesData = await getAllLanguages();
        const socialMediasData = await getAllSocialMedias();
        setLanguages(languagesData);
        setSocialMedias(socialMediasData);
      } catch (err: any) {
        setError(err.message || "Failed to fetch languages");
      } finally {
        setLoading(false);
      }
    };

    loadLanguagesAndSocialMedias();
  }, []);

  useEffect(() => {
    if (!user) return;
    const loadUserFocuses = async () => {
      const focusesData = await getAllFocusesByUser();
      setFocuses(focusesData);
    };
    loadUserFocuses();
  }, [user]); // Ajouter user comme dépendance

  return (
    <UserContext.Provider
      value={{ user, languages, socialMedias, loading, error, focuses }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
