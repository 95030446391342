import { PlusIcon, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Conversation } from "../../types";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import ChatConversation from "./ChatConversation";
import ChatSidebarLoader from "./ChatSidebarLoader";

interface ChatSidebarProps {
  searchConversation: string;
  setSearchConversation: (value: string) => void;
  conversationsLoading: boolean;
  filteredConversations: Conversation[];
  handleConversationSelect: (id: string) => void;
  selectedConversation?: Conversation;
  setCurrentConversationChats: (conversation: any) => void;
}

const ChatSidebar = ({
  searchConversation,
  setSearchConversation,
  conversationsLoading,
  filteredConversations,
  handleConversationSelect,
  selectedConversation,
  setCurrentConversationChats,
}: ChatSidebarProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full w-52 flex-col rounded-5xl bg-blue-light p-4">
      <h1 className="mx-auto mb-6 pt-3 text-2xl font-bold text-blue">Chat</h1>

      <Button
        className="flex w-full gap-3 bg-white px-3 font-light text-blue hover:text-white"
        hover="jumping"
        onClick={() => {
          setCurrentConversationChats(null);
          navigate("/chat", { replace: true }); // au lieu de window.history.replaceState
        }}
      >
        <PlusIcon className="size-5" />
        <p className="text-sm">Nouvelle discussion</p>
      </Button>

      <div className="mt-2 flex items-center justify-between gap-2">
        <div className="flex w-full items-center rounded-full bg-white px-4">
          <Search className="text-blue" />
          <Input
            placeholder="Rechercher..."
            className="w-full rounded-full border-none py-2 text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
            value={searchConversation}
            onChange={(e) => setSearchConversation(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-8 flex-1 overflow-y-auto">
        {conversationsLoading ? (
          <ChatSidebarLoader />
        ) : (
          <ul className="flex flex-col gap-2">
            <ChatConversation
              title="Aujourd'hui"
              conversations={filteredConversations}
              checkYesterday={true}
              handleConversationSelect={handleConversationSelect}
              selectedConversation={selectedConversation?.conversation_id}
            />
            <ChatConversation
              title="7 derniers jours"
              conversations={filteredConversations}
              checkLastWeek={true}
              handleConversationSelect={handleConversationSelect}
              selectedConversation={selectedConversation?.conversation_id}
            />
            <ChatConversation
              title="Depuis le début"
              conversations={filteredConversations}
              checkOlderThanWeek={true}
              handleConversationSelect={handleConversationSelect}
              selectedConversation={selectedConversation?.conversation_id}
            />
          </ul>
        )}
      </div>
    </div>
  );
};

export default ChatSidebar;
