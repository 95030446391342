const ChatMessagesLoader = () => (
  <div className="mx-auto flex h-full w-11/12 flex-col justify-center space-y-4 p-4 xl:w-10/12 2xl:w-9/12">
    <div className="ml-auto w-2/3 animate-pulse">
      <div className="h-44 rounded-xl bg-gray-200" />
    </div>

    <div className="w-2/3 animate-pulse">
      <div className="h-64 rounded-xl bg-gray-200" />
    </div>

    <div className="ml-auto w-1/2 animate-pulse">
      <div className="h-40 rounded-xl bg-gray-200" />
    </div>
  </div>
);

export default ChatMessagesLoader;
