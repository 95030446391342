import {
  Check,
  Copy,
  Pencil,
  Star,
  ThumbsDown,
  ThumbsUp,
  Trash,
} from "lucide-react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CHATBOT_QUESTIONS } from "../../lib/constants";
import { cn } from "../../lib/utils";
import { Chat } from "../../types";
import AvatarUser from "../Avatar";

interface ChatMessagesProps {
  currentConversationChats: Chat[];
  searchTerm: string;
  user: any;
  answerLoading: boolean;
  moodeeWaitMessage: string;
  answerFinished: boolean;
  setChatContent: (content: string) => void;
  setModifiedChatId: (id: string | null) => void;
  handleDeleteChat: (id: string) => void;
  handleFavoriteChat: (id: string) => void;
  handleThumbs: (id: string, thumbs: string) => void;
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

const ChatMessages = ({
  currentConversationChats,
  searchTerm,
  user,
  answerLoading,
  moodeeWaitMessage,
  answerFinished,
  setChatContent,
  setModifiedChatId,
  handleDeleteChat,
  handleFavoriteChat,
  handleThumbs,
  messagesEndRef,
}: ChatMessagesProps) => {
  const [hasCopied, setHasCopied] = useState(false);

  const filteredMessages = React.useMemo(() => {
    if (!searchTerm.trim()) return currentConversationChats;

    return currentConversationChats.filter((chat) =>
      chat.content.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [currentConversationChats, searchTerm]);

  return (
    <div className="my-5 flex flex-1 flex-col-reverse overflow-y-scroll">
      <div className="mx-auto w-11/12 space-y-4 xl:w-10/12 2xl:w-9/12">
        {filteredMessages.map((chat, idx) => (
          <div className="group" key={chat.chat_id}>
            <div
              className={cn(
                chat.author !== "Moodee" ? "ml-auto" : "mr-auto",
                "flex w-fit max-w-[95%] flex-col",
              )}
            >
              <div
                className={cn(
                  chat.author !== "Moodee" && "flex-row-reverse",
                  "flex items-start gap-3",
                )}
              >
                {chat.author === "Moodee" ? (
                  <img
                    src="/moodee-v1.png"
                    alt="logo"
                    className="size-7 rounded-full"
                  />
                ) : (
                  <AvatarUser user={user} className="size-7" />
                )}
                {answerLoading &&
                  idx === filteredMessages.length - 1 &&
                  chat.author === "Moodee" && (
                    <span className="animate-pulse transition">
                      {moodeeWaitMessage}
                    </span>
                  )}
                <ReactMarkdown
                  className={cn(
                    chat.author !== "Moodee" &&
                      "rounded-2xl border border-gray-200 px-4 py-3 shadow-md",
                    "chat-response",
                  )}
                  children={chat.content}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ node, children, ...props }) => (
                      <a {...props} target="_blank" rel="noopener noreferrer">
                        {children}
                      </a>
                    ),
                  }}
                />
              </div>
              {answerFinished && (
                <div
                  className={cn(
                    chat.author === "Moodee"
                      ? "ml-10 justify-start"
                      : "mr-12 justify-end",
                    "mt-2 flex gap-3 opacity-0 group-hover:opacity-60",
                  )}
                >
                  {chat.author !== "Moodee" && (
                    <>
                      <button onClick={() => handleFavoriteChat(chat.chat_id)}>
                        <Star
                          className={cn(
                            chat.favorite === true &&
                              "fill-yellow-400 text-yellow-400",
                            "size-3.5",
                          )}
                        />
                      </button>
                      <button
                        onClick={() => {
                          setChatContent(chat.content);
                          setModifiedChatId(chat.chat_id);
                        }}
                      >
                        <Pencil className="size-3.5" />
                      </button>
                    </>
                  )}
                  {chat.author === "Moodee" && (
                    <>
                      <button onClick={() => handleThumbs(chat.chat_id, "UP")}>
                        <ThumbsUp
                          className={cn(
                            chat.thumbs === "UP" && "fill-green text-green",
                            "size-3.5",
                          )}
                        />
                      </button>
                      <button
                        onClick={() => handleThumbs(chat.chat_id, "DOWN")}
                      >
                        <ThumbsDown
                          className={cn(
                            chat.thumbs === "DOWN" && "fill-orange text-orange",
                            "size-3.5",
                          )}
                        />
                      </button>
                    </>
                  )}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(chat.content);
                      setHasCopied(true);
                      setTimeout(() => {
                        setHasCopied(false);
                      }, 1000);
                    }}
                  >
                    {hasCopied ? (
                      <div className="flex items-center gap-1">
                        <Check className="size-4" />
                      </div>
                    ) : (
                      <Copy className="size-3.5" />
                    )}
                  </button>
                  <button onClick={() => handleDeleteChat(chat.chat_id)}>
                    <Trash className="size-3.5" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        <div ref={messagesEndRef} />

        {answerFinished && currentConversationChats.length <= 1 && (
          <div className="flex flex-wrap gap-2">
            {CHATBOT_QUESTIONS.map((question) => (
              <button
                key={question.label}
                className="flex w-fit justify-end rounded-lg border border-blue bg-transparent px-1.5 py-2 text-sm text-dark transition-all hover:-translate-y-0.5"
                onClick={() => setChatContent(question.prompt)}
              >
                {question.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessages;
