import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllConversationsByUser,
  getFavoritesPromptsByUser,
} from "../lib/askMoodee";
import { getAllFocusesByUser } from "../lib/focus";
import { useDebounce } from "../lib/useDebounce";
import { Chat, Conversation, Focus, SocialMedia } from "../types";

export const useInitialData = () => {
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [focuses, setFocuses] = useState<Focus[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const [favoritePrompts, setFavoritePrompts] = useState<Chat[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setConversationsLoading(true);
        const [focusesData, conversationsData, favoritesData] =
          await Promise.all([
            getAllFocusesByUser(),
            getAllConversationsByUser(),
            getFavoritesPromptsByUser(),
          ]);

        setFocuses(focusesData);
        setConversations(conversationsData);
        setFavoritePrompts(favoritesData);
        setIsInitialDataLoaded(true);
      } catch (err: any) {
        setError(err.message || "Failed to fetch initial data.");
      } finally {
        setConversationsLoading(false);
      }
    };

    loadInitialData();
  }, []);

  return {
    isInitialDataLoaded,
    focuses,
    conversations,
    favoritePrompts,
    setFavoritePrompts,
    conversationsLoading,
    error,
    setConversations,
  };
};

export const useUrlParams = (socialMedias: SocialMedia[]) => {
  const location = useLocation();
  const [selectedFocus, setSelectedFocus] = useState<string>();
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string[]>([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const focusParams = urlParams.get("focus");
    const socialMediasParams = urlParams.get("social_medias")?.split("-");

    if (focusParams && socialMediasParams?.length) {
      const socialMediaIds = socialMediasParams
        .map(
          (formattedName) =>
            socialMedias.find((sm) => sm.formatted_name === formattedName)
              ?.social_media_id,
        )
        .filter((id) => id !== undefined) as string[];

      setSelectedFocus(focusParams);
      setSelectedSocialMedia(socialMediaIds);
    }
  }, [location.search, socialMedias]);

  return {
    selectedFocus,
    setSelectedFocus,
    selectedSocialMedia,
    setSelectedSocialMedia,
  };
};

// hooks/useConversationSearch.ts
export const useConversationSearch = (conversations: Conversation[]) => {
  const [searchConversation, setSearchConversation] = useState("");
  const [filteredConversations, setFilteredConversations] = useState<
    Conversation[]
  >([]);
  const debouncedSearchConversation = useDebounce(searchConversation, 500);

  useEffect(() => {
    if (debouncedSearchConversation.trim() === "") {
      setFilteredConversations(conversations);
    } else {
      const filtered = conversations.filter((conversation) =>
        conversation.focus_names.some((focus) =>
          focus
            .toLowerCase()
            .includes(debouncedSearchConversation.toLowerCase()),
        ),
      );
      setFilteredConversations(filtered);
    }
  }, [debouncedSearchConversation, conversations]);

  return {
    searchConversation,
    setSearchConversation,
    filteredConversations,
  };
};

export const useChatState = () => {
  const [currentConversationChats, setCurrentConversationChats] = useState<
    Chat[] | null
  >(null);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [answerFinished, setAnswerFinished] = useState(true);
  const [chatContent, setChatContent] = useState("");
  const [moodeeWaitMessage, setMoodeeWaitMessage] = useState(
    "Analyse du prompt...",
  );
  const [modifiedChatId, setModifiedChatId] = useState<string | null>(null);

  useEffect(() => {
    if (answerLoading) {
      const messages = [
        "Analyse du prompt...",
        "Recherche et enrichissement des données...",
        "Raisonnement...",
        "Génération de la réponse...",
      ];

      // Premier message
      setMoodeeWaitMessage(messages[0]);

      // Messages suivants
      messages.slice(1).forEach((msg, index) => {
        setTimeout(
          () => {
            setMoodeeWaitMessage(msg);
          },
          (index + 1) * 5000,
        );
      });
    }
  }, [answerLoading]);

  return {
    currentConversationChats,
    setCurrentConversationChats,
    answerLoading,
    setAnswerLoading,
    answerFinished,
    setAnswerFinished,
    chatContent,
    setChatContent,
    moodeeWaitMessage,
    modifiedChatId,
    setModifiedChatId,
  };
};

export const useConversationNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToConversation = useCallback(
    (conversationId: string) => {
      navigate(`?conversation_id=${conversationId}`);
    },
    [navigate],
  );

  const getCurrentConversationId = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get("conversation_id");
  }, [location.search]);

  return {
    navigateToConversation,
    getCurrentConversationId,
  };
};
